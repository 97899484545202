@import url('../fonts/font-awesome-min.css');
@import url('https://fonts.googleapis.com/css?family=Cedarville+Cursive');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import 'pe-icon-7-stroke';

@import "../../node_modules/bootstrap/scss/functions";
@import "./global/vars";
@import "./customVariables";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/root";



@import "./custom/bootstrap";
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/utilities/api";


// Actions
@import "./custom/actions/buttons";
@import "./custom/actions/dropzone";


// Feedback Indicators
@import "./custom/notify";

// Images and icons
@import "./custom/icons";

@import "../components/common/Autocomplete/_Autocomplete.scss";




// Forms
@import "./custom/form";
@import "./custom/form/select";
@import "./custom/form/form";
@import "./custom/form/radio";
@import "./custom/form/checkbox";
@import "./custom/form/switch";


@import "./custom/navs.scss";

@import "./custom/collapsible";


@import "./custom/fonts";
@import "./custom/common";
@import "./custom/texts";

@import "./custom/debitcard";
@import "./custom/advisor";
@import "./custom/peyme";
@import "./custom/banner";
@import "./custom/banking";
@import "./custom/table";
@import "./custom/helper";
@import "./custom/shared";
@import "./custom/anime";
@import "./custom/auth";
@import "./custom/global";
@import "./custom/header";
@import "./custom/headings";
@import "./custom/badges";
@import "./custom/modal";
@import "./custom/cards";
@import "./custom/dropdown";
@import "./custom/basic";
@import "./custom/sidebar";
@import "./custom/launchpad";
@import "./custom/dashboard";
@import "./custom/products";
@import "./custom/estimates";
@import "./custom/invoice";
@import "./custom/events-timeline";
@import "./custom/setting";

@import "./custom/lists";
@import "./custom/payments";
@import "./custom/onboarding";
@import "./custom/content";


@import "./custom/responsive";
@import "./invoice/view-summery";
@import "./invoice/add-invoice";
@import "./invoice/invoice-preview";
@import "./invoice/invoice-view";
// @import "./invoice/classic";
// @import "./invoice/modern";
@import "./invoice/invoice-onBoarding";
@import "./recurring/recurring";
@import "./checkout/checkout";
@import "./recurring/view-recurring";
@import "./invoice/receipt-preview";




@import "./payments/filter";
@import "./payments/record_list";

@import "./custom/business";
@import "./custom/recurringInvoice";
@import "./statements/statements";
@import './custom/profile';
@import './custom/miniSidebar';
@import './custom/productsServices';
@import './custom/customer';
@import './custom/vendor';
@import './custom/bills';
@import './custom/import';
@import './custom/receipts';
@import './custom/success';
@import './custom/reciept';
@import './custom/datepicker';
@import './custom/ModernTemplate';
@import './custom/ClassicTemplate';
@import './custom/ContemporaryTemplate';
@import './custom/printCustom';
@import './custom/utilities';
@import './custom/type';
@import './custom/payout';
@import './custom/subscription';
@import './custom/mobile-app';
@import './custom/dark-mode';


@import "../components/app/components/Banking/components/index";
@import "./onboarding/customizeScreen.css";
@import "./onboarding/ste4Screen.css";

@import './pages/accounting';
// @import './pages/chart-of-account';
// @import './pages/create-Edit-Chart-Popup';
// @import './pages/transaction';
// @import './pages/transactionForm';