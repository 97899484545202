// @font-face {
//   font-family: 'Peymynt Grotesk';
//   src: url('https://cdn.peymynt.com/static/web-assets/fonts/CircularStd-Bold.woff2') format('woff2'),
//     url('https://cdn.peymynt.com/static/web-assets/fonts/CircularStd-Bold.woff') format('woff');
//   font-weight: $font-weight-bold;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Peymynt Grotesk';
//   src: url('https://cdn.peymynt.com/static/web-assets/fonts/CircularStd-Medium.woff2') format('woff2'),
//     url('https://cdn.peymynt.com/static/web-assets/fonts/CircularStd-Medium.woff') format('woff');
//   font-weight: 500;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Peymynt Grotesk';
//   src: url('https://cdn.peymynt.com/static/web-assets/fonts/CircularSpotifyText-Light.woff2') format('woff2'),
//     url('https://cdn.peymynt.com/static/web-assets/fonts/CircularSpotifyText-Light.woff') format('woff');
//   font-weight: 300;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Peymynt Grotesk';
//   src: url('https://cdn.peymynt.com/static/web-assets/fonts/CircularStd-Book.woff2') format('woff2'),
//     url('https://cdn.peymynt.com/static/web-assets/fonts/CircularStd-Book.woff') format('woff');
//   font-weight: 400;
//   font-style: normal;
// }


@font-face {
  font-family: 'Peymynt Grotesk';
  src: url('../../assets/fonts/CircularStd-Bold.woff2') format('woff2'),
    url('../../assets/fonts/CircularStd-Bold.woff') format('woff');
  font-weight: $font-weight-bold;
  font-style: normal;
}

@font-face {
  font-family: 'Peymynt Grotesk';
  src: url('../../assets/fonts/CircularStd-Medium.woff2') format('woff2'),
    url('../../assets/fonts/CircularStd-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Peymynt Grotesk';
  src: url('../../assets/fonts/CircularSpotifyText-Light.woff2') format('woff2'),
    url('../../assets/fonts/CircularSpotifyText-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Peymynt Grotesk';
  src: url('../../assets/fonts/CircularStd-Book.woff2') format('woff2'),
    url('../../assets/fonts/CircularStd-Book.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}



@font-face {
  font-family: 'Peymynt Sans';
  src: url('../../assets/fonts/graphik/GraphikWeb-Medium.woff2') format('woff2'),
      url('../../assets/fonts/graphik/GraphikWeb-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Peymynt Sans';
  src: url('../../assets/fonts/graphik/GraphikWeb-Regular.woff2') format('woff2'),
      url('../../assets/fonts/graphik/GraphikWeb-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Peymynt Sans';
  src: url('../../assets/fonts/graphik/GraphikWeb-Light.woff2') format('woff2'),
      url('../../assets/fonts/graphik/GraphikWeb-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Peymynt Sans';
  src: url('../../assets/fonts/graphik/GraphikWeb-Semibold.woff2') format('woff2'),
      url('../../assets/fonts/graphik/GraphikWeb-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}



/*
@font-face {
  font-family: 'GT Walsheim';
  src: url('/assets/fonts/gtwalsheim/GTWalsheimPro-Light.woff2') format('woff2'),
    url('/assets/fonts/gtwalsheim/GTWalsheimPro-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'GT Walsheim';
  src: url('/assets/fonts/gtwalsheim/GTWalsheimPro-Regular.woff2') format('woff2'),
    url('/assets/fonts/gtwalsheim/GTWalsheimPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GT Walsheim';
  src: url('../../assets/fonts/gtwalsheim/GTWalsheimPro-Bold.woff2') format('woff2'),
    url('../../assets/fonts/gtwalsheim/GTWalsheimPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'GT Walsheim';
  src: url('../../assets/fonts/gtwalsheim/GTWalsheimPro-Medium.woff2') format('woff2'),
    url('../../assets/fonts/gtwalsheim/GTWalsheimPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}*/
