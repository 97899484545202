.payments-list {
  thead {
    .py-table__cell {
      padding-top: 20px;
      padding-right: .5rem;
      padding-left: .5rem;
    }

    .py-table__cell-amount {
      text-align: right !important;
    }
  }

  table {
    .py-table__cell {
      width: auto;
      vertical-align: middle;
    }
  }

  .react-bs-container-body .table tr:nth-child(even) td {
    padding: 0px !important;
  }
}

.payment-list__item-container {
  margin-left: -10px;
  margin-right: -10px;
  color: #1c252c;
}

.payments-list__item--body {
  display: flex;
  align-items: center;

  .payment-list__item-amount {
    flex-grow: 1;
    margin-right: 2rem;

    >div {
      padding-right: 1rem;
      padding-left: 1rem;
      color: rgba($black, .8);

      &:last-child {
        color: $black;
        border-top: 2px solid $black;
      }
    }
  }


  .payment-cards__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 630px;
    flex-grow: 1;
    position: relative;

    .py-text--hint {
      margin-bottom: .5rem;
    }

    .py-payment-card {
      color: $white;
    }
  }

  .payment-cards__indicator {
    padding-top: 45px;
    max-width: 60px;
    width: 60px;
    text-align: center;
    line-height: 30px;
  }
}


.payment-list__footer {
  padding: 1.5rem 1rem;
  display: flex;
  justify-content: flex-end;

  .btn:last-child {
    margin-left: 1rem;
  }
}

.py-payment__bank {
  display: flex;
  align-items: center;
}

.py-payment__bank-icon {
  width: 56px;
  min-width: 56px;
  height: 56px;
  min-height: 56px;
  background: rgba(255, 255, 255, .2);
  text-align: center;
  line-height: 56px;
  border-radius: 8px;
  font-size: 1.8rem;
  margin-right: 1rem;
}

.py-payment__bank-name {
  word-break: break-word;
  white-space: pre-wrap;
  text-transform: uppercase;
  line-height: 1.2;
}

.py-payment__bank__link {
  position: absolute;
  right: 20px;
  bottom: 20px;

  .py-text--link {
    font-size: 12px;
    color: rgba($white, .8) !important;
    text-decoration: underline;

    &:hover {
      color: $white !important;
    }
  }
}


.Refund-Modal__body {
  padding: 48px 72px;

  .Select-menu-outer {
    max-width: 320px;
  }

  .refund-modal__amount {
    margin-right: 4px;

    .form-control:disabled {
      background: none;
      // border: 0;
      width: auto;
      min-width: 0;
      display: inline-block;
    }
  }

}

.rbt-menu.show {
  padding-top: 8px;
  padding-bottom: 8px;
}
